import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/react-deployment",
  "date": "2015-08-25",
  "title": "REACT DEPLOYMENT",
  "author": "admin",
  "tags": ["development", "javascript", "react.js"],
  "featuredImage": "feature.jpg",
  "excerpt": "In this article, we are discussing about how to perform a production deployment for React"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Before you think about deploying your React JS project using Webpack, there are two things you want to do preparing for a production build:`}</p>
    <ul>
      <li parentName="ul">{`Configure a script to run in your `}<inlineCode parentName="li">{`package.json`}</inlineCode>{` file`}</li>
      <li parentName="ul">{`Create a `}<inlineCode parentName="li">{`production config`}</inlineCode></li>
    </ul>
    <h2>{`Creating the Script`}</h2>
    <p>{`Assume you have a script for deploying to your development environment in `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` you could run it with `}<strong parentName="p">{`npm run dev`}</strong>{` as shown below. Now let us set up `}<strong parentName="p">{`npm run deploy`}</strong>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`{
  "name": "react-deployment-test",
  "version": "0.1.0",
  "description": "React Deployment Test Project",
  "main": "app/main.js",
  "scripts": {
    "dev": "webpack-dev-server --devtool eval --progress --colors --hot --content-base build",
    "deploy": "NODE_ENV=production webpack -p --config webpack.production.config.js"
  },
  "author": "",
  "license": "ISC",
  "devDependencies": {
    "webpack": "^1.4.13",
    "webpack-dev-server": "^1.6.6"
  },
  "dependencies": {}
}
`}</code></pre>
    <p>{`As you can see we are just running Webpack with the production argument and pointing to a different configuration file. We also use the environment variable `}<strong parentName="p">{`production`}</strong>{` to allow our required modules to do their optimizations. Let's create the config file now.`}</p>
    <h2>{`Creating the Production Config`}</h2>
    <p>{`So there really is not much difference in creating the dev and production versions of your Webpack config. You basically point to a different output path and there are no workflow configurations or optimizations. What you also want to bring into this configuration is cache handling.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var path = require('path');
var node_modules_dir = path.resolve(__dirname, 'node_modules');

var config = {
  entry: path.resolve(__dirname, 'app/main.js'),
  output: {
    path: path.resolve(__dirname, 'dist'),
    filename: 'bundle.js'
  },
  module: {
    loaders: [{
      test: /\\.js$/,

      // There is not need to run the loader through
      // vendors
      exclude: [node_modules_dir],
      loader: 'babel'
    }]
  }
};

module.exports = config;
`}</code></pre>
    <h2>{`Deployment`}</h2>
    <p>{`Run `}<strong parentName="p">{`npm run deploy`}</strong>{` in the root of the project. Webpack will now run in production mode. It does some optimizations on its own, but also React JS will do its optimizations. Look into caching for even more production configuration.`}</p>
    <h2>{`Single Bundle`}</h2>
    <p>{`Let's have a look at the simplest setup you can create for your application. Use a single bundle when:`}</p>
    <ul>
      <li parentName="ul">{`You have a small application`}</li>
      <li parentName="ul">{`You will rarely update the application`}</li>
      <li parentName="ul">{`You are not too concerned about perceived initial loading time`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var path = require('path');
var config = {
  entry: path.resolve(__dirname, 'app/main.js'),
  output: {
    path: path.resolve(__dirname, 'dist'),
    filename: 'bundle.js'
  },
  module: {
    loaders: [{
      test: /\\.js$/,
      loader: 'babel'
    }]
  }
};

module.exports = config;
`}</code></pre>
    <h2>{`Split up the vendors`}</h2>
    <p>{`When your application is depending on other libraries, especially large ones like React JS, you should consider splitting those dependencies into its own vendor bundle. This will allow you to do updates to your application, without requiring the users to download the vendor bundle again. Use this strategy when:`}</p>
    <ul>
      <li parentName="ul">{`When your vendors reaches a certain percentage of your total app bundle. Like 20% and up`}</li>
      <li parentName="ul">{`You will do quite a few updates to your application`}</li>
      <li parentName="ul">{`You are not too concerned about perceived initial loading time, but you do have returning users and care about optimizing the experience when you do updates to the application`}</li>
      <li parentName="ul">{`Users are on mobile`}</li>
    </ul>
    <p>{`webpack.production.config.js`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var path = require('path');
var webpack = require('webpack');
var node_modules_dir = path.resolve(__dirname, 'node_modules');

var config = {
  entry: {
    app: path.resolve(__dirname, 'app/main.js'),

    // Since react is installed as a node module, node_modules/react,
    // we can point to it directly, just like require('react');
    vendors: ['react']
  },
  output: {
    path: path.resolve(__dirname, 'dist'),
    filename: 'app.js'
  },
  module: {
    loaders: [{
      test: /\\.js$/,
      exclude: [node_modules_dir],
      loader: 'babel'
    }]
  },
  plugins: [
    new webpack.optimize.CommonsChunkPlugin('vendors', 'vendors.js')
  ]
};

module.exports = config;
`}</code></pre>
    <p>{`This configuration will create two files in the `}<strong parentName="p">{`dist/`}</strong>{` folder. `}<inlineCode parentName="p">{`app.js`}</inlineCode>{` and `}<inlineCode parentName="p">{`vendors.js`}</inlineCode>{`.`}</p>
    <Message type="warn" title="Important!" content="Remember to add both files to your HTML file, or you will get the error: Uncaught ReferenceError: webpackJsonp is not defined." mdxType="Message" />
    <h2>{`Multiple Entry Points`}</h2>
    <p>{`Maybe you are building an application that has multiple URLs. An example of this would be a solution where you have two, or more, different URLs responding with different pages. Maybe you have one user page and one admin page. They both share a lot of code, but you do not want to load all the admin stuff for normal users. That is a good scenario for using multiple entry points. A list of use cases could be:`}</p>
    <ul>
      <li parentName="ul">{`You have an application with multiple isolated user experiences, but they share a lot of code`}</li>
      <li parentName="ul">{`You have a mobile version using less components`}</li>
      <li parentName="ul">{`You have a typical user/admin application where you do not want to load all the admin code for a normal user`}</li>
    </ul>
    <p>{`Let us create an example with a mobile experience using less components: webpack.production.config.js`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var path = require('path');
var webpack = require('webpack');
var node_modules_dir = path.resolve(__dirname, 'node_modules');

var config = {
  entry: {
    app: path.resolve(__dirname, 'app/main.js'),
    mobile: path.resolve(__dirname, 'app/mobile.js'),
    vendors: ['react'] // And other vendors
  },
  output: {
    path: path.resolve(__dirname, 'dist'),
    filename: '[name].js' // Notice we use a variable
  },
  module: {
    loaders: [{
      test: /\\.js$/,
      exclude: [node_modules_dir],
      loader: 'babel'
    }]
  },
  plugins: [
    new webpack.optimize.CommonsChunkPlugin('vendors', 'vendors.js')
  ]
};
`}</code></pre>
    <h2>{`Lazy Loading Entry Points`}</h2>
    <p>{`It is also possible to lazy load entry points. This means that you load parts of your application as they are requested. A typical scenario for this would be that your users only visit specific parts of the application. And an example of that would be twitter.com. You do not always visit your profile page, so why load the code for that? Here is a summary of requirements:`}</p>
    <ul>
      <li parentName="ul">{`You have a relatively big application where users can visit different parts of it`}</li>
      <li parentName="ul">{`You do care a lot about initial render time`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var path = require('path');
var webpack = require('webpack');
var node_modules_dir = path.resolve(__dirname, 'node_modules');

var config = {
  entry: {
    app: path.resolve(__dirname, 'app/main.js'),
    vendors: ['react']
  },
  output: {
    path: path.resolve(__dirname, 'dist'),
    filename: 'app.js'
  },
  module: {
    loaders: [{
      test: /\\.js$/,
      exclude: [node_modules_dir],
      loader: 'babel'
    }]
  },
  plugins: [
    new webpack.optimize.CommonsChunkPlugin('vendors', 'vendors.js')
  ]
};

module.exports = config;
`}</code></pre>
    <p>{`So we are pretty much back where we started with a split application and vendors bundle. You do not really define your lazy dependencies in a configuration, Webpack automatically understands them when analyzing your code. So let us see how we would lazy load a profile page:`}</p>
    <p>{`main.js (Using ES6 syntax)`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import Feed from './Feed.js';

class App extends React.Component {
  constructor() {
    this.state = { currentComponent: Feed };
  }
  openProfile() {
    require.ensure([], () => {
      var Profile = require('./Profile.js');
      this.setState({
        currentComponent: Profile
      });
    });
  }
  render() {
   return (
      return <div>{this.state.currentComponent()}</div>
    );
  }
}
React.render(<App/>, document.body);
`}</code></pre>
    <p>{`So this is just an example. You would probably hook this up to a router, but the important part is using require.ensure.`}</p>
    <p>{`What is the array on the first argument?: If you try to lazy load a chunk that depends on another lazy-loaded chunk you can set it as a dependency in the array. Just type in the path to the chunk. E.g. `}{`['./FunnyButton.js']`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      